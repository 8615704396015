.linkItem {
    height: 60px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
   }

.linkItem p{
  color: black;
  text-align: center;
  line-height: 50px;
}
.linkItem img{
  vertical-align: middle;
  max-height: 50px;
}
.linkItem i{
  color:white;
}
img[src="#"] {
  display: none;
}